.header {
    padding: 0;
    padding-right: 40px;
    height: 65px;
    .ant-menu {
        background: transparent;
        color: @white;
        .ant-menu-item {
            &:hover {
                color: @white;
            }
        }
    }
}
.header__trigger {
    color: @white;
}